/* Desktop Nav */
.navbarContainer {
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 2;
  transition: all 0.2s ease-in;
}

.navbarBg {
  background: #a7d7c5;
  box-shadow: 0px 2px 5px rgb(128, 181, 160, 0.6);
}

.mobileNavbarBg {
  background: #fdfdf8;
  box-shadow: 0px 2px 5px rgb(128, 181, 160, 0.6);
}

.navbarVisible {
  top: 0;
}

.navbarHide {
  top: -100px;
}

.navHeader {
  display: flex;
  align-items: center;
  padding-left: 3rem;
}

.navHeaderLink {
  font-size: calc(26px + 0.390625vw);
  letter-spacing: 3px;
  text-decoration: none;
}

.navHeaderLink,
.navLink {
  color: #5c8d89;
  font-weight: 700;
}

.navLinks {
  display: flex;
  justify-content: space-around;
  list-style: none;
  width: 50%;
}

.navLinks li .navLink {
  font-size: calc(13px + 0.390625vw);
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.navLink:hover,
.active {
  color: #12714d;
}

.navLink:active {
  color: #1eb178;
}
