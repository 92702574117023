/* Mobile Nav */
.mobileNav {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(50%);
  width: 300px;
  z-index: 2;
}

.navSidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #a7d7c5;
  width: 300px;
  height: 100vh;
  transform: translateX(-50%);
}

.navToggleBtn {
  outline: none;
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(130%, 40%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
}

.mobileNavOverlay {
  background: transparent;
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  width: 100vw;
  height: 100vh;
  z-index: -1;
  cursor: pointer;
}

.mobileNavList,
.mobileNavItem {
  margin: 0;
  padding: 0;
}

.mobileNavList {
  padding: 25px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, 40%);
  width: 230px;
}

.mobileNavItem {
  color: #5c8d89;
  font-size: calc(16px + 0.390625vw);
  font-weight: 700;
  list-style: none;
  margin-bottom: 20px;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.mobileNavResume {
  color: #5c8d89;
  text-decoration: none;
}
