/* Main Projects component styling */
.projects {
  --header-text-color: #5c8d89;
  --primary-color: #ddfddb;
  --secondary-color: #12714d;
  min-height: 100vh;
  max-width: 1000px;
  margin: 0 auto;
  padding: 25px 0;
}

.projectsHeader {
  font-size: calc(60px + 0.390625vw);
  color: var(--header-text-color);
  text-align: center;
}

.projectsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  margin-top: 3rem;
  padding: 0 30px;
  list-style: none;
}

.projectsGrid li {
  margin-bottom: 2rem;
}

/* ProjectCard styling */
.cardContainer {
  box-shadow: 0 10px 15px -10px rgb(128, 181, 160, 0.6);
  background-color: var(--primary-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  padding: 1rem;
  overflow: auto;
}

.cardIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardLink {
  text-decoration: none;
  transition: all 0.2s ease-out;
}

.cardLink:hover {
  opacity: 0.5;
}

.cardTitle {
  color: var(--header-text-color);
}

.cardDescription {
  color: var(--secondary-color);
  font-size: calc(10px + 0.390625vw);
}

.cardTechList {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  flex-wrap: wrap;
  padding: 0;
  margin: 20px 0 0 0;
  list-style: none;
  max-height: 30px;
}

.cardTechList li {
  color: var(--secondary-color);
  opacity: 0.6;
  font-size: calc(10px + 0.390625vw);
  margin-right: 15px;
}
