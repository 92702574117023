.contact {
  --primary-color: #5c8d89;
  --secondary-color: #80b5a0;
  --modal-text-color: #275854;
  --text-font-size: calc(11px + 0.390625vw);
  max-width: 550px;
  min-height: 50vh;
  padding: 100px 0;
}

.contactHeader {
  color: var(--primary-color);
  font-size: calc(60px + 0.390625vw);
  text-align: center;
}

.contactFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactFooter p,
.contactFooter button {
  color: var(--secondary-color);
}

.contactText {
  font-size: var(--text-font-size);
  text-align: center;
  padding: 0 3rem;
}

.contactBtn {
  background-color: #fdfdf8;
  border: 3px solid #6da28e;
  border-radius: 6px;
  outline: none;
  font-size: var(--text-font-size);
  margin-top: 25px;
  padding: 20px 30px;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.contactBtn:hover {
  background-color: rgba(236, 236, 211, 0.6);
  opacity: 0.6;
}

.contactBtn:active {
  background-color: rgba(236, 236, 211, 0.3);
  opacity: 0.3;
}

.backdrop {
  background-color: #000000cc;
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  z-index: 3;
}

.modalContainer {
  background-color: #a7d7c5;
  border-radius: 10px;
  box-sizing: border-box;
  margin: 1rem;
  padding: 1rem 2.25rem;
  width: 75%;
}

.modalContainer h2,
.modalContainer p {
  color: var(--modal-text-color);
}

.modalContainer p {
  font-size: var(--text-font-size);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalCloseBtn {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 20px;
  padding: 2px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.modalCloseBtn:hover {
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 0.6;
}

.modalCloseBtn:active {
  background-color: rgba(255, 255, 255, 1);
  opacity: 0.3;
}

label {
  color: var(--modal-text-color);
  font-size: var(--text-font-size);
  margin-top: 10px;
  margin-bottom: 5px;
}

label,
input,
textarea {
  display: block;
}

input,
textarea {
  font-family: inherit;
  font-size: var(--text-font-size);
  border-radius: 5px;
  border: 2px solid #ffffff;
  outline: none;
  box-sizing: border-box;
  padding: 8px 10px;
  width: 100%;
}

input:focus,
textarea:focus {
  border-color: var(--modal-text-color);
}

textarea {
  resize: none;
}

.submitBtn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.submitBtn button {
  background-color: #a7d7c5;
  border: 3px solid var(--modal-text-color);
  border-radius: 5px;
  color: var(--modal-text-color);
  font-size: var(--text-font-size);
  padding: 1.25rem 3rem;
  cursor: pointer;
}

.successMsg,
.errorMsg {
  border-radius: 5px;
  color: #fdfdf8;
  padding: 1rem;
}

.successMsg {
  background-color: #1ea370b1;
}

.errorMsg {
  background-color: rgb(222, 60, 60);
}
