.header {
  --primary-text-color: #5c8d89;
  --secondary-text-color: #80b5a0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding: 0 2rem;
  min-height: 100vh;
}

h1 {
  color: var(--primary-text-color);
  font-size: calc(85px + 0.390625vw);
  font-weight: 700;
  word-break: break-word;
  margin: 0;
}

.headerName {
  margin-top: 20px;
}

.caption {
  color: var(--secondary-text-color);
  font-size: calc(14px + 0.390625vw);
  margin: 0;
}

.caption {
  width: clamp(32ch, 50%, 40ch);
  margin-top: 15px;
}
